import React from 'react'
import InvestMain from './components/Home/InvestSection/InvestMain'
import HomePage from './components/Home'

const App = () => {
  return (
    <>
      <HomePage />
    </>
  )
}

export default App